@font-face {
  font-family: 'Interstate';
  src: url(./fonts/Interstate\ Light.otf) format('opentype');
}

@font-face {
  font-family: 'Interstate';
  font-weight: bold;
  src: url(./fonts/Interstate\ Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Interstate';
  font-style: italic;
  src: url(./fonts/Interstate\ Light\ Italic.otf) format('opentype');
}

@font-face {
  font-family: 'Interstate Condensed';
  src: url(./fonts/Interstate\ Light\ Condensed.otf) format('opentype');
}

@font-face {
  font-family: 'Interstate Condensed';
  font-weight: bold;
  src: url(./fonts/Interstate\ Regular\ Condensed.otf) format('opentype');
}
